import {
	testimonialColor, ltGtMediaQuery, xsMediaQuery, colorsNew, boxShadow,
} from 'root/src/client/web/commonStyles'
import withStyles from '@material-ui/core/styles/withStyles'

export const wideCardBreakpoint = 881

const keyframes = {
	'@global @keyframes slideUp': {
		'0%': {
			transform: 'translateY(50%)',
		},
		'100%': {
			transform: 'translateY(0px)',
		},
	},
	'@global @keyframes slideDown': {
		'0%': {
			transform: 'translateY(-50%)',
		},
		'100%': {
			transform: 'translateY(0px)',
		},
	},
	'@global @keyframes scaleUp': {
		'0%': {
			transform: 'scale(1)',
		},
		'100%': {
			transform: 'scale(1.1)',
		},
	},
	'@global @keyframes scaleDown': {
		'0%': {
			transform: 'scale(1.1)',
		},
		'100%': {
			transform: 'scale(1)',
		},
	},
}

export const projectCardStyle = {
	...keyframes,
	projectSummary: {
		display: 'flex',
		width: '100%',
		gap: 10,
		height: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	bgBlack: {
		backgroundColor: 'black !important',
	},
	smOpacity: {
		filter: 'opacity(0.3)',
	},
	mdOpacity: {
		filter: 'opacity(0.1)',
	},
	maxDares: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		gap: 8,
	},
	maxDaresText: {
		color: colorsNew.pink,
		margin: 0,
		textAlign: 'center',
		fontSize: 10,
	},
	darkIcon: {
		'& path': {
			fill: 'black',
		},
	},
	textTransformNone: {
		textTransform: 'none',
	},
	dropPlatforms: {
		display: 'flex',
		listStyleType: 'none',
		margin: 0,
		padding: 0,
		gap: 10,
	},
	platformIconContainer: {
		borderRadius: '100%',
		backgroundColor: 'white',
		width: 20,
		height: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.6)',
	},
	cardRoot: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		backgroundColor: 'rgba(0, 0, 0, .9)',
		filter: 'drop-shadow(9px 9px 10px rgba(0, 0, 0, 0.6))',
		margin: [[0, 10, 20]],
		color: 'white',
		alignSelf: 'center',
		borderRadius: 25,
		position: 'relative',
		overflow: 'hidden',
		boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
		transition: '0.1s',
		zIndex: 0,
		width: 320,
		height: 450,
		'&:hover, &:active, &:focus': {
			boxShadow: '0 1px 26px 0 rgba(0,0,0,1)',
		},
	},
	imageDot: {
		background: '#FFEA00',
		border: '2px solid #FFF',
		borderRadius: '100%',
		bottom: 0,
		height: 10,
		position: 'absolute',
		right: 0,
		transform: 'translate(2%, 2%)',
		width: 10,
	},
	labelContainer: {
		borderRadius: 5,
		display: 'flex',
		placeContent: 'center',
	},
	labelText: {
		fontFamily: 'Roboto',
		fontSize: 10,
		fontWeight: 500,
		letterSpacing: '.02em',
		padding: '6px 8px',
		color: 'black',
		textTransform: 'uppercase',
	},
	highlightedSection: {
		display: 'flex',
		flexDirection: 'column',
	},
	projectTimeLeft: {
		position: 'absolute',
		right: 15,
		top: 10,
		display: 'flex',
		gap: 6,
		zIndex: 10,
	},
	projectTimeLeftValue: {
		textShadow: '1px 1px 1px #000000',
	},
	imageOverlay: {
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, .2)',
		position: 'absolute',
		top: 0,
		transition: '.150s',
		left: 0,
	},
	overlayLg: {
		backgroundColor: 'rgba(0, 0, 0, .7)',
	},
	highlightedSectionHeader: {
		color: colorsNew.teal,
		fontSize: 12,
		textTransform: 'uppercase',
		margin: '3px 0px',
	},
	highlightedSectionText: {
		color: colorsNew.light1,
		fontSize: 12,
		margin: '3px 0px',
	},
	assigneeImage: {
		width: '85%',
		height: '85%',
		borderRadius: '100%',
	},
	scaledUp: {
		animation: 'scaleUp .150s both',
	},
	projectCardHeader: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
	},
	positionInitial: {
		position: 'initial',
	},
	imageContainer: {
		height: '35%',
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: 'top',
	},
	scaledDown: {
		animation: 'scaleDown .150s both',
	},
	thumbnail: {
		width: '100%',
		height: '100%',
	},
	tiktokThumbnail: {
		objectFit: 'cover',
	},
	cardRootEx: {
		backgroundColor: testimonialColor,
		color: 'white',
		alignSelf: 'center',
		height: 324,
		borderRadius: '4px',
		boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
		cursor: 'pointer',
		transition: '0.1s',
		width: 255,
		position: 'relative',
	},
	cardRootPlaceholder: {
		background: colorsNew.dark2,
		width: 255,
	},
	hover: {
		boxShadow: '0 1px 26px 0 rgba(0,0,0,1)',
	},
	cardBg: {
		backgroundPosition: 'top',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		width: '100%',
		borderRadius: 4,
	},
	cardHeader: {
		zIndex: 1,
	},
	ctaButton: {
		textTransform: 'uppercase',
		zIndex: 999,
	},
	cardFooter: {
		height: '100%',
		margin: [[15, 25, 25, 25]],
		display: 'flex',
		flexDirection: 'column',
	},
	cardTextContainer: {
		boxSizing: 'border-box',
		margin: [[1, 0, 7]],
		lineHeight: '13px',
		minHeight: 12,
		fontSize: 13,
	},
	cardText: {
		color: '#6d7a87',
	},
	cardTextValue: {
		color: 'white',
	},
	playIconSpace: {
		position: 'absolute',
		height: 80,
	},
	playIconSpaceEx: {
		height: 100,
	},
	overlay: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxHeight: 28,
		backgroundColor: colorsNew.dark1,
	},
	playIconContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	projectAssigne: {
		display: 'flex',
		justifyContent: 'flex-start',
		height: 'auto',
		padding: [[18, 14]],
	},
	uppercase: {
		textTransform: 'uppercase',
	},
	assigneesNames: {
		margin: [[0, 14, 0, 19]],
		fontSize: 12,
		[ltGtMediaQuery]: {
			fontSize: 12,
		},
	},
	namesContainer: {
		display: 'flex',
		justifyContent: 'end',
		width: '85%',
		alignItems: 'center',
	},
	circleBorder: {
		width: 50,
		height: 50,
		marginRight: -6,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '0px 0px 10px 2px #00FFED',
		filter: 'drop-shadow(0px 0px 8px #00FFED)',
		border: '2px solid #fff',
		borderRadius: '100%',
		backgroundColor: 'transparent',
		position: 'relative',
	},
	circleImage: {
		height: '100%',
		width: '100%',
		borderRadius: '100%',
	},
	assigneeImg: {
		width: 50,
		height: 50,
		marginRight: -6,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '0px 0px 10px 2px #00FFED',
		filter: 'drop-shadow(0px 0px 8px #00FFED)',
		border: '2px solid #fff',
		borderRadius: '100%',
		backgroundColor: 'transparent',
		position: 'relative',
	},
	iconColor: {
		color: 'white',
		'&:hover': {
			color: '#ddd',
		},
	},
	expired: {},
	footerHeaderContainer: {
		fontSize: 15,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 5,
	},
	gameTitle: {
		display: '-webkit-box',
		maxWidth: 400,
		'-webkit-line-clamp': 3,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	footerBody: {
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '100%',
		gap: 5,
		marginTop: 15,
	},
	dareCardBody: {
		margin: [[8, 0]],
		height: 'auto',
		justifyContent: 'flex-start',
	},
	cardPreview: {
		animation: 'slideDown .150s',
	},
	darePreview: {
		margin: [[20, 0, 10, 0]],
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	cardDetails: {
		animation: 'slideUp .150s',
		gap: 20,
		justifyContent: 'flex-start',
	},
	headerTextH3: {
		wordBreak: 'break-word',
		fontSize: 15,
		fontWeight: '500',
		fontStyle: 'normal',
		fontStretch: 'normal',
		lineHeight: '1.2',
		letterSpacing: '0.3px',
		margin: 0,
		zIndex: 201,
	},
	descriptionContainer: {
		overflow: 'hidden',
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		marginTop: 10,
	},
	descriptionContainerClamp2: {
		WebkitLineClamp: 2,
	},
	descriptionContainerClamp4: {
		WebkitLineClamp: 4,
	},
	descriptionContainerClamp6: {
		WebkitLineClamp: 6,
	},
	descriptionText: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: 11,
		lineHeight: '150%',
		margin: 0,
		letterSpacing: '0.08px',
		display: '-webkit-box',
		'-webkit-line-clamp': 4,
		'line-clamp': 4,
		'-webkit-box-orient': 'vertical',
	},
	button: {
		height: 30,
		width: 200,
		border: 'none',
		'& span': {
			textTransform: 'none',
			fontSize: 13,
			lineHeight: 1.4,
		},
	},
	footerBottomSection: {
		width: '100%',
		zIndex: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: 10,
	},
	buttonText: {
		fontWeight: 400,
	},
	createDareButtonContainer: {
		height: 28,
		margin: [['auto', 0, 5]],
	},
	bodyCard: {
		height: '65%',
		display: 'flex',
		flexDirection: 'column',
	},
	markContainerEx: {
		position: 'absolute',
		top: 8,
		left: 8,
	},
	marksContainer: {
		gap: 4,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	status: {
		display: 'flex',
		gap: 4,
		margin: [[0, 10]],
	},
	pin: {
		zIndex: 10,
	},
	mark: {
		fontSize: 10,
		color: 'white',
		textAlign: 'center',
		padding: [[4, 6]],
		borderRadius: 2,
		marginRight: 10,
	},
	pledgedMark: {
		backgroundColor: '#0091ff',
	},
	acceptedMark: {
		backgroundColor: '#00C8BA',
	},
	completedMark: {
		backgroundColor: colorsNew.pink,
	},
	inReviewMark: {
		backgroundColor: '#FFBB00',
	},
	expiredMark: {
		backgroundColor: '#e02020',
	},
	pendingMark: {
		backgroundColor: '#6fa0c7',
	},
	headerContainer: {
		width: '100%',
		display: 'flex',
		height: '100%',
		padding: [[0, 16]],
	},
	headerContainerPlaceholder: {
		justifyContent: 'flex-end',
		backgroundColor: colorsNew.dark2,
	},
	loadingContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		flexDirection: 'row',
		position: 'relative',
		backgroundColor: 'transparent',
	},
	loadingBlock: {
		position: 'absolute',
		top: 0,
		width: '100%',
		height: 'calc(100% - 75px)',
		opacity: 0.6,
		zIndex: 1,
		textAlign: 'center',
	},
	loading: {
		padding: 10,
		position: 'relative',
		width: 25,
		height: 25,
	},
	loadingText: {
		fontFamily: 'Roboto',
		fontWeight: 'bold',
		fontStyle: 'normal',
		fontStretch: 'normal',
		letterSpacing: 'normal',
		textAlign: 'center',
	},
	infiniteStyle: {
		fontSize: 18,
		position: 'relative',
		bottom: '-0.15em',
		lineHeight: 0,
	},
	liveAlert: {
		backgroundColor: '#E91916',
		position: 'absolute',
		right: -10,
		top: -2,
		zIndex: 2000,
		borderRadius: 3,
		fontSize: 10,
		padding: [[0, 3]],
	},
	noMarginLeft: {
		marginLeft: 0,
	},
	'hiddenPlaceholder-1': {
		'@media (max-width: 688px)': {
			display: 'none',
		},
	},
	'hiddenPlaceholder-2': {
		'@media (max-width: 1268px)': {
			display: 'none',
		},
	},
	'hiddenPlaceholder-3': {
		'@media (max-width: 1668px)': {
			display: 'none',
		},
	},
	'hiddenPlaceholder-4': {
		'@media (max-width: 2068px)': {
			display: 'none',
		},
	},
	'hiddenPlaceholder-5': {
		'@media (max-width: 2468px)': {
			display: 'none',
		},
	},
	'hiddenPlaceholder-6': {
		'@media (max-width: 2868px)': {
			display: 'none',
		},
	},
	pointer: {
		cursor: 'pointer',
	},
	dropLockContainer: {
		width: 43,
		height: 43,
		backgroundColor: 'black',
		top: 10,
		right: 10,
		borderRadius: 22,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&:hover > div': {
			visibility: 'visible',
		},
	},
	dropEventBadgesWrapper: {
		display: 'flex',
		gap: 4,
	},
	dropEventBadges: {
		width: '50%',
		display: 'flex',
		gap: 4,
		margin: [[5, 10]],
	},
	justifyEnd: {
		justifyContent: 'flex-end',
	},
	tooltip: {
		visibility: 'hidden',
		width: 150,
		backgroundColor: '#132D4A',
		position: 'absolute',
		top: 40,
		right: 20,
		borderRadius: 4,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '5px 5px 10px black',
		zIndex: 999,
	},
	requirementDisplay: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: [[5, 10]],
	},
	privateDropBannerLayoutContainer: {
		padding: [[5, 9]],
		borderRadius: 9,
		backgroundColor: 'rgba(0, 0, 0, .6)',
	},
	privateDropBannerLayoutContent: {
	},
	fullSize: {
		width: '100%',
		height: '100%',
	},
	flex: {
		display: 'flex',
	},
	flexCenter: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	flexCol: {
		flexDirection: 'column',
	},
	ctaButtonOverlay: {
		position: 'absolute',
		zIndex: 20,
	},
	fullSizeImage: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: 'top',
	},
	gamePlatformsList: {
		padding: 0,
		listStyleType: 'none',
		display: 'flex',
		gap: 8,
	},
	absolute: {
		position: 'absolute',
	},
	rewardWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
	},
	remainingRewardsText: {
		margin: 0,
		fontSize: '.6rem',
		fontWeight: 500,
		color: colorsNew.light4,
	},
	remainingRewardsContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
		padding: [[5, 0]],
	},
	estimatedTimeContainer: {
		display: 'flex',
		gap: 8,
		alignItems: 'center',
	},
	dareCardSectionTitle: {
		margin: 0,
		fontSize: 11,
		color: colorsNew.teal,
		textWrap: 'pretty',
	},
	dareHintContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
	},
	dareHeaderSection: {
		display: 'flex',
		alignItems: 'center',
		gap: 4,
	},
	justifyBetween: {
		justifyContent: 'space-between',
	},
	dareDescription: {
		fontSize: 12,
		textTransform: 'none',
		whiteSpace: 'initial',
	},
	blurred: {
		filter: 'blur(5px)',
	},
	dareHint: {
		fontSize: 12, fontWeight: 400,
	},
	revealHintButton: {
		marginTop: 15,
		cursor: 'pointer',
		justifySelf: 'center',
		background: 'none',
		border: 'none',
		textDecoration: 'underline',
		color: colorsNew.teal,
	},
	shareDareButton: {
		background: 'none',
		border: 'none',
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	shareDareLink: {
		background: 'none',
		border: 'none',
		display: 'flex',
		textDecoration: 'underline',
		fontSize: 18,
		margin: 10,
		color: colorsNew.teal,
	},
	fullHeightSpaced: {
		justifyContent: 'space-between',
		height: '100%',
	},
	projectTitle: {
		margin: 0,
		fontSize: 14,
		fontWeight: 400,
		color: colorsNew.light1,
	},
	badgesWrapper: {
		position: 'absolute',
		width: '100%',
		left: 0,
		top: 0,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	badgesContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: [[10, 20]],
	},
	buttonTooltip: {
		fontSize: '.9rem',
		margin: 5,
		zIndex: 10,
	},
	earnRewardsIcon: {
		backgroundColor: 'transparent',
	},
	textTruncate: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}

export const projectVideoCardStyle = {
	...projectCardStyle,
	cardRoot: {
		backgroundColor: colorsNew.dark2,
		width: 824,
		height: 308,
		borderRadius: 8,
		boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.4), 0 0 15px 8px rgba(0, 0, 0, 0.5)',
		flexDirection: 'row-reverse',
		[ltGtMediaQuery]: {
			width: 284,
			height: 470,
			borderRadius: 4,
			boxShadow: 'none',
			flexDirection: 'column',
		},
		'@media(max-width: 412px)': {
			width: 240,
			height: 420,
		},
		'@media(max-width: 360px)': {
			width: 200,
			height: 410,
		},
	},
	cardTextContainer: {
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		margin: [[1, 0, 7]],
		lineHeight: '13px',
		minHeight: 12,
		fontSize: 13,
	},
	cardText: {
		textTransform: 'upperCase',
		color: colorsNew.light5,
		fontSize: 9,
	},
	player: {
		width: '100%',
		height: 308,
		[ltGtMediaQuery]: {
			height: 162,
		},
		'@media(max-width: 360px)': {
			width: 200,
		},
	},
	projectAssigne: {
		...projectCardStyle.projectAssigne,
		marginRight: 'auto',
		backgroundColor: 'initial',
		position: 'relative',
	},
	bodyCard: {
		height: '60%',
	},
	thumbnailWrapper: {
		margin: [[0, 'auto']],
		position: 'relative',
		overflow: 'hidden',
		borderRadius: [[0, 10, 10, 0]],
		maxWidth: 548,
		height: '100%',
		cursor: 'pointer',
		[ltGtMediaQuery]: {
			borderRadius: [[4, 4, 0, 0]],
			height: '47vw',
			width: '100%',
		},
		[xsMediaQuery]: {
			height: '80vw',
		},
	},
	thumbnail: {
		height: '100%',
		objectFit: 'cover',
	},
	playIconWrapper: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)',
		cursor: 'pointer',
	},
	cardFooter: {
		...projectCardStyle.cardFooter,
		height: '100%',
		width: 284,
		margin: 0,
		borderRadius: [[10, 0, 0, 10]],
		padding: 20,
		[ltGtMediaQuery]: {
			width: '100%',
			borderRadius: [[0, 0, 4, 4]],
		},
		'@media(max-width: 360px)': {
			padding: 9,
		},
	},
	descriptionContainer: {
		...projectCardStyle.descriptionContainer,
		margin: [[10, 0, 0, 0]],
	},
	assigneesNames: {
		...projectCardStyle.assigneesNames,
		margin: 0,
	},
	assigneeImgWrapper: {
		border: '3px solid #fff',
		boxShadow: boxShadow('#00FFED'),
		width: 50,
		height: 50,
		padding: 6,
		borderRadius: 100,
		backgroundColor: 'transparent',
		position: 'relative',
		[xsMediaQuery]: {
			height: 30,
			width: 30,
			padding: 0,
		},
	},
	assigneeImg: {
		position: 'absolute',
		borderRadius: 100,
		width: 50,
		height: 50,
		[xsMediaQuery]: {
			height: 30,
			width: 30,
		},
	},
	footerHeaderContainer: {
		...projectCardStyle.footerHeaderContainer,
		zIndex: 0,
		[xsMediaQuery]: {
			marginTop: 50,
		},
	},
}

export const pointsMarketplaceItemStyle = {
	...projectCardStyle,
	cardRoot: {
		...projectCardStyle.cardRoot,
		backgroundColor: colorsNew.dark2,
		cursor: '',
		boxShadow: '3px 3px 25px 0 rgba(0,0,0,1)',
		borderRadius: '15px',
	},
	cardBg: {
		...projectCardStyle.cardBg,
		height: 161,
		backgroundPosition: 'center',
	},
	itemName: {
		height: 30,
		backgroundColor: colorsNew.light5,
		textAlign: 'center',
	},
	productDetails: {
		height: 181,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: colorsNew.dark2,
	},
	price: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: 10,
	},
	description: {
		width: '90%',
		display: 'flex',
		flexDirection: 'column',
		height: '9em',
		overflow: 'hidden',
		lineHeight: '1em',
	},
	button: {
		position: 'absolute',
		width: 200,
		bottom: 20,
	},
}

export const withProjectCardStyle = withStyles(projectCardStyle, { name: 'MuiProjectCard' })
